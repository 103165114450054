import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "antd";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import GridLoader from "../../re-usables/loaders/GridLoader";
import "./Categories.scss";
import CategoryCard from "./CategoryCard";

export default function Categories() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const { Search } = Input;
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const orderValue = sessionStorage.getItem("orderValue");

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getRequest(urls.getAllCategories());
      setCategories(response[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className="caterories-page">
      <div
        className="order-value"
        style={{ fontWeight: 500, color: "#7B7B7B" }}
      >
        Order Value:{" "}
        <span style={{ color: "#0052A0" }}>
          {" "}
          {inr} {Number(orderValue).toFixed(2) || "--"}
        </span>
      </div>

      <Search
        placeholder="Search products"
        onSearch={(value) =>
          navigate("/categoryItems", {
            state: {
              keyword: value,
              indentNumber: location?.state?.indentNumber,
            },
          })
        }
        style={{
          width: "100%",
        }}
      />

      <div className="scroll-area">
        <div className="grid-view">
          {categories.length
            ? categories.map((item) => (
                <CategoryCard
                  item={item}
                  indentNumber={location?.state?.indentNumber}
                  rateCatag={location?.state?.rateCatag}
                />
              ))
            : null}
        </div>
        {loading ? <GridLoader /> : null}
      </div>
      <div className="continue-btn">
        <button
          className="secondary-btn"
          onClick={() => navigate(-1, { replace: true })}
        >
          Back
        </button>
        <button
          className="primary-btn"
          onClick={() =>
            navigate("/order", {
              state: {
                indentNumber: location.state?.indentNumber,
                rateCatag: location?.state?.rateCatag,
              },
            })
          }
        >
          Go to order
        </button>
      </div>
    </div>
  );
}
