import { Empty, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ListLoader from "../../re-usables/loaders/ListLoader";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/downloadIcon.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default function RateChart() {
  let customerId = localStorage.getItem("userId");
  const [rateChartData, setRateChartData] = useState();
  const [loading, setLoading] = useState(true);
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const moment = require('moment');
// Get the current date
const currentDate = moment();
// Format the current date as "DD-MMM-YYYY" (e.g., 01-Apr-2024)
const formattedDate = currentDate.format('DD-MMM-YYYY');

  const getChartData = async () => {
    try {
      setLoading(true);
      const res = await getRequest(urls.rateChartData(customerId));
      setRateChartData(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  let rateChartColumns = [
    { title: "Product", dataIndex: "PACKET_NAME", width: 130 },
    {
      title: "Basic Rate",
      dataIndex: "BASIC_RATE",
      width: 60,
      align: "center",
    },
    {
      title: "GST Amt",
      dataIndex: "GST",
      width: 65,
      align: "center",
    },
    {
      title: "Rate",

      dataIndex: "RATE",
      width: 60,
      align: "center",
    },
  ];

  if (appFlavour === "CHAMUL")
    rateChartColumns.push({
      title: "MRP",

      dataIndex: "MRP",
      width: 60,
      align: "center",
    });

  useEffect(() => {
    getChartData();
  }, []);

  const downloadPdf = () => {
    if (!rateChartData.length) return;

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(25);

    const title = `\t\t\t\t\tTumkur Milk Uinon Ltd\n\t\t\t\t\tRateChart ${formattedDate}`;
    const headers = rateChartColumns.map((column) => column.title);
    const data = rateChartData.map((row) =>
      rateChartColumns.map((column) => row[column.dataIndex])
    );

    doc.text(title, 40, 40);
    doc.autoTable({
      startY: 100,
      head: [headers],
      body: data,
    });

    const base64String = doc.output("datauristring"); // Generate base64 string
    const message = JSON.stringify({ url: base64String });
    // Send message to the React Native side
    window.ReactNativeWebView.postMessage(message);
  };

  return (
    <div>
      <div className="page-header">
        Rate Chart{" "}
        <DownloadIcon onClick={downloadPdf} style={{marginLeft:'20px'}} />
      </div>

      <Table
        dataSource={loading ? [] : rateChartData}
        locale={{
          emptyText: loading ? <ListLoader /> : <Empty />,
        }}
        columns={rateChartColumns}
        bordered
        scroll={{ y: "calc(100vh - 380px)", x: "calc(100vw - 40px" }}
        pagination={false}
        style={{ marginTop: 15 }}
      />
    </div>
  );
}
