import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMG_BASE_URL } from "../../../http/axiosConfig/config";
import Card from "../../re-usables/card/Card";

export default function OrderItem({ item, rateCatag }) {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const navigate = useNavigate();
  const appFlavour = process.env.REACT_APP_FLAVOUR;
  const [imageUrl, setImageUrl] = useState(
    `${IMG_BASE_URL}/images/${item.IMG_PATH}`
  );

  return (
    <Card>
      <div style={{ display: "flex", gap: 10 }}>
        <img
          width={50}
          height={50}
          src={imageUrl}
          alt={item.PACKET_NAME}
          onError={(e) =>
            (e.target.src = require("../../../assets/images/error-loading-image.jpg"))
          }
          onClick={() =>
            rateCatag
              ? navigate("/product-details", {
                  state: {
                    packetCode: item?.PACKET_CODE,
                    rateCatag: rateCatag,
                  },
                })
              : null
          }
        />
        <div style={{ flex: 1, fontSize: 12, fontWeight: 600 }}>
          <div className="packet-name">{item.PACKET_NAME}</div>
          {appFlavour === "MYMUL" ? (
            <div>
              Rate: {inr} {item.RATE}
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="qty">
              Qty:{" "}
              {appFlavour === "MYMUL"
                ? item.PKT
                : item.INDENT_IN === "C"
                ? item.QTY
                : item.PKT}{" "}
              {item.IND_IN}
            </div>
            {appFlavour === "MYMUL" ? <div> Crt/Box: {item.QTY}</div> : null}
            <div className="amount" style={{ fontWeight: 600 }}>
              {inr} {Number(item.AMOUNT).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
