import React, { useEffect, useState } from "react";
import "./Indents.scss";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ItemCard from "./ItemCard";
import ListLoader from "../../re-usables/loaders/ListLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Spin } from "antd";
import { toast } from "react-toastify";
import moment from "moment";

export default function Indents() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const location = useLocation();
  const readOnly = location.state?.readOnly;
  const [indentItems, setIndentItems] = useState([]);
  const [indentNumber, setIndentNumber] = useState();
  const [orderValue, setOrderValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedBatch = JSON.parse(sessionStorage.getItem("selectedBatch"));
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const getIndentNumber = async () => {
    try {
      setLoading(true);
      const indentNumber = await getRequest(
        urls.getIndentNumber(userId, selectedBatch.ROUTE_CODE)
      );
      const indentItems = await getRequest(
        urls.getIndentItems(indentNumber[0].INDENT_NUMBER)
      );
      const orderValue = await getRequest(
        urls.orderValue(indentNumber[0].INDENT_NUMBER)
      );
      // const myOrderValue = indentItems.result.reduce((prev, curr) => {
      //   let prod = curr.QTY * curr.RATE;
      //   return prev + prod;
      // }, 0);
      setOrderValue(Number(orderValue[0].TOTAL));
      setIndentNumber(indentNumber[0]);
      sessionStorage.setItem("indentNumber", JSON.stringify(indentNumber[0]));
      setIndentItems(indentItems.result);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function formatToProperDecimal(val) {
    var floorValue = Math.floor(Number(val));
    var remainder = val - floorValue;
    if (remainder === 0) return val;
    if (remainder > 0 && remainder <= 0.25) return floorValue + 0.25;
    if (remainder > 0.25 && remainder <= 0.5) return floorValue + 0.5;
    if (remainder > 0.5 && remainder <= 0.75) return floorValue + 0.75;
    if (remainder > 0.75) return floorValue + 1;
  }

  const updateValues = (index, value, type = undefined) => {
    let objCopy = [...indentItems];
    let row = objCopy[index];
    let diff;
    let orderVal;
    if (type === "dropDown" && value !== row.INDENT_IN) {
      row.INDENT_IN = value;
      if (value === "P") {
        let ov = orderValue - row.QTY * row.RATE * row.CRATE_OR_NOT;
        setOrderValue(ov + row.PKT * row.RATE);
      } else {
        let ov = orderValue - row.PKT * row.RATE;
        setOrderValue(ov + row.QTY * row.RATE * row.CRATE_OR_NOT);
      }
    } else {
      if (value < 0) return;
      if (value > row.FGS_QTY) {
        toast.error(`${row.FGS_QTY} units in stock`, {
          toastId: "out-of-stock-error",
        });
        return;
      }

      if (row.INDENT_IN === "P") {
        diff = value - Number(row.PKT);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.RATE
            : orderValue + Math.abs(diff) * row.RATE;
      } else {
        //formatToproperdecimals
        value = formatToProperDecimal(value);
        diff = value - Number(row.QTY);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.CRATE_OR_NOT * row.RATE
            : orderValue + Math.abs(diff) * row.CRATE_OR_NOT * row.RATE;
      }
      if (
        diff > 0 &&
        creditLimit[0].FD_LOCK === "Y" &&
        orderVal > Number(creditLimit[0].CREDIT_LIMIT)
      ) {
        toast.error(`Adding this item will exceed the credit limit`, {
          toastId: "credit-limit-error",
        });
        return;
      }

      setOrderValue(orderVal);
      row.INDENT_IN === "C" ? (row.QTY = value) : (row.PKT = value);
    }
    objCopy.splice(index, 1, row);

    setIndentItems(objCopy);
  };

  const mymulUpdate = (index, value, type = undefined) => {
    if (value === undefined || value === null || value < 0) return;

    let objCopy = [...indentItems];
    let row = objCopy[index];
    let diff = row.PKT;
    let orderVal;

    if (value > row.FGS_QTY) {
      toast.error(`${row.FGS_QTY} units in stock`, {
        toastId: "out-of-stock-error",
      });
      return;
    }

    if (type === "pkt") {
      diff = value - diff;

      if (value > 0 && value < row.MINORDER) {
        toast.error(`Minimum order qty is ${row.MINORDER}`, {
          toastId: "min-order-error",
        });
        return;
      }

      if (value > 0 && value > row.MAXORDER) {
        toast.error(`Maximum order qty is ${row.MAXORDER}`, {
          toastId: "max-order-error",
        });
        return;
      }

      if (value > 0 && value % row.MINORDER !== 0) {
        toast.error(`Qty must be in multiple of ${row.MINORDER}`, {
          toastId: "not-multiple-error",
        });
        return;
      }

      row.PKT = value;
      row.QTY = value / row.CRATE_OR_NOT;
    }

    if (type === "crate") {
      let convertedVal = value * row.CRATE_OR_NOT;
      diff = convertedVal - diff;

      if (convertedVal > 0 && convertedVal < row.MINORDER) {
        toast.error(`Minimum order qty is ${row.MINORDER}`, {
          toastId: "min-order-error",
        });
        return;
      }

      if (convertedVal > 0 && convertedVal > row.MAXORDER) {
        toast.error(`Maximum order qty is ${row.MAXORDER}`, {
          toastId: "max-order-error",
        });
        return;
      }

      if (convertedVal > 0 && convertedVal % row.MINORDER !== 0) {
        toast.error(`Qty must be in multiple of ${row.MINORDER}`, {
          toastId: "not-multiple-error",
        });
        return;
      }
      row.PKT = convertedVal;
      row.QTY = value;
    }

    orderVal =
      diff < 0
        ? orderValue - Math.abs(diff) * row.RATE
        : orderValue + Math.abs(diff) * row.RATE;

    if (
      diff > 0 &&
      creditLimit[0].FD_LOCK === "Y" &&
      orderVal > Number(creditLimit[0].CREDIT_LIMIT)
    ) {
      toast.error(`Adding this item will exceed the credit limit`, {
        toastId: "credit-limit-error",
      });
      return;
    }

    objCopy.splice(index, 1, row);

    setOrderValue(orderVal);
    setIndentItems(objCopy);
  };

  const saveIndent = async (showSuccessToast = false) => {
    try {
      setLoading(true);
      let qtyArr = [];
      let idArr = [];
      let indentIn = [];
      indentItems.map((item) => {
        let qty = item.INDENT_IN === "C" ? String(item.QTY) : String(item.PKT);
        qtyArr.push(qty);
        idArr.push(item.PACKET_CODE);
        indentIn.push(item.INDENT_IN);
      });

      await getRequest(
        urls.saveIndent(indentNumber.INDENT_NUMBER, qtyArr, idArr, indentIn)
      );
      if (showSuccessToast) toast.success("Order placed successfully");
    } catch (error) {
      toast.error("Could not save indent, try again.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getIndentNumber();
  }, []);

  useEffect(() => {
    sessionStorage.setItem("orderValue", orderValue);
  }, [orderValue]);

  return (
    <div>
      <div className="action-bar">
        <div
          className="order-value"
          style={{ fontWeight: 500, color: "#7B7B7B" }}
        >
          Order Value:{" "}
          <span style={{ color: "#0052A0" }}>
            {" "}
            {inr} {orderValue?.toFixed(2)}
          </span>
        </div>
      </div>
      <div className="indent-list indent-list-scroll-area">
        {indentItems && indentItems.length
          ? indentItems.map((item, index) => (
            <ItemCard
              item={item}
              indentNumber={indentNumber}
              index={index}
              updateValues={updateValues}
              mymulUpdate={mymulUpdate}
              readOnly={readOnly}
              appFlavour={appFlavour}
            />
          ))
          : null}
        {loading ? <ListLoader /> : null}
      </div>
      <div className="continue-btn">
        {!readOnly ? (
          <button
            className="primary-btn"
            onClick={() => {
              const selectedBatch = JSON.parse(sessionStorage.getItem("selectedBatch"))

              const isBetween = moment().isBetween(
                moment(selectedBatch.ST_TIME, "hh.mm A"),
                moment(selectedBatch.CUT_OFF, "hh.mm A"),
                "minutes")

              if (!isBetween) {
                if (
                  creditLimit &&
                  creditLimit[0].FD_LOCK === "Y" &&
                  orderValue > Number(creditLimit[0].CREDIT_LIMIT)
                ) {
                  toast.error(`Credit limit exceeded`, {
                    toastId: "credit-limit-error",
                  });
                  return;
                }
                setIsModalOpen(true);
              }
              else {
                toast.error(`Place order between ${selectedBatch.ST_TIME} to ${selectedBatch.CUT_OFF} for this shift.\n Press Home to go back`, {
                  // Additional options can be added here if needed
                });

              }

              // if (
              //   creditLimit &&
              //   creditLimit[0].FD_LOCK === "Y" &&
              //   orderValue > Number(creditLimit[0].CREDIT_LIMIT)
              // ) {
              //   toast.error(`Credit limit exceeded`, {
              //     toastId: "credit-limit-error",
              //   });
              //   return;
              // }
              // setIsModalOpen(true);
            }}
          >
            Place Order
          </button>
        ) : (
          <div className="action-btns" style={{ width: "100%" }}>
            <button
              className="secondary-btn"
              onClick={() => navigate(-1, { replace: true })}
            >
              Back
            </button>
            <button
              className="primary-btn"
              onClick={() =>
                navigate("/order", {
                  state: {
                    indentNumber: indentNumber?.INDENT_NUMBER,
                    rateCatag: indentNumber?.RATE_CATAG,
                  },
                })
              }
            >
              View Order
            </button>
          </div>
        )}
      </div>

      <Modal
        className="bottom-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="header-img-container">
          <img
            src={require("../../../assets/images/add-more.png")}
            alt="add-more"
            width={100}
            height={100}
          />
        </div>

        <p>Would you like to add more items?</p>
        {loading ? (
          <Spin style={{ display: "flex", justifyContent: "center" }} />
        ) : (
          <div className="action-btns">
            <button
              className="secondary-btn"
              onClick={() => {
                saveIndent(true);
                navigate("/order", {
                  state: {
                    indentNumber: indentNumber?.INDENT_NUMBER,
                    rateCatag: indentNumber?.RATE_CATAG,
                  },
                });
              }}
            >
              No
            </button>
            <button
              className="primary-btn"
              onClick={() => {
                saveIndent();
                navigate("/categories", {
                  state: {
                    indentNumber: indentNumber?.INDENT_NUMBER,
                    rateCatag: indentNumber?.RATE_CATAG,
                  },
                });
              }}
            >
              Yes
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
}
