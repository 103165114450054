import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "../../re-usables/card/Card";
import { IMG_BASE_URL } from "../../../http/axiosConfig/config";

export default function CategoryCard({ item, indentNumber }) {
  const [imageUrl, setImageUrl] = useState(
    `${IMG_BASE_URL}/images/${item.IMG_FILE}`
  );

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate("/categoryItems", {
          state: {
            categoryName: item.CAT_NAME,
            categoryId: item.CATEGORY_ID,
            indentNumber: indentNumber,
            rateCatag: location?.state?.rateCatag,
          },
        })
      }
    >
      <Card className="categories-card">
        <img
          src={imageUrl}
          alt={item.PACKET_NAME}
          onError={(e) =>
            (e.target.src = require("../../../assets/images/error-loading-image.jpg"))
          }
        />
        <div className="category-info">
          <div className="category-name">{item.CAT_NAME}</div>
        </div>
      </Card>
    </div>
  );
}
