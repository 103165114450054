import { Empty, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import ItemCard from "../indent/ItemCard";
import "./CategoryItems.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ListLoader from "../../re-usables/loaders/ListLoader";
import { toast } from "react-toastify";

export default function CategoryItems() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [categoryItems, setCategoryItems] = useState([]);
  const [loading, setLoading] = useState([]);
  const [ValuesChanged, setValuesChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let storedOrderValue = sessionStorage.getItem("orderValue");
  const [orderValue, setOrderValue] = useState(
    storedOrderValue ? Number(storedOrderValue) : 0
  );

  const location = useLocation();
  const navigate = useNavigate();
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const appFlavour = process.env.REACT_APP_FLAVOUR;

  const getCategoryItems = async () => {
    try {
      setLoading(true);
      const response = await getRequest(
        urls.getCategoryItems(
          location.state.indentNumber,
          location.state.categoryId
        )
      );
      response[0].forEach((element) => {
        element["QTY"] = 0;
      });
      setCategoryItems(response[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function formatToProperDecimal(val) {
    var floorValue = Math.floor(Number(val));
    var remainder = val - floorValue;
    if (remainder === 0) return val;
    if (remainder > 0 && remainder <= 0.25) return floorValue + 0.25;
    if (remainder > 0.25 && remainder <= 0.5) return floorValue + 0.5;
    if (remainder > 0.5 && remainder <= 0.75) return floorValue + 0.75;
    if (remainder > 0.75) return floorValue + 1;
  }

  const updateValues = (index, value, type = undefined) => {
    setValuesChanged(true);
    let objCopy = [...categoryItems];
    let row = objCopy[index];
    let diff;
    let orderVal;
    if (type === "dropDown" && value !== row.INDENT_IN) {
      row.INDENT_IN = value;
      if (value === "P") {
        let ov = orderValue - row.QTY * row.RATE * row.CRATE_OR_NOT;
        setOrderValue(ov + row.PKT * row.RATE);
      } else {
        let ov = orderValue - row.PKT * row.RATE;
        setOrderValue(ov + row.QTY * row.RATE * row.CRATE_OR_NOT);
      }
    } else {
      if (value < 0) return;
      if (value > row.FGS_QTY) {
        toast.error(`${row.FGS_QTY} units in stock`, {
          toastId: "out-of-stock-error",
        });
        return;
      }

      if (row.INDENT_IN === "P") {
        diff = value - Number(row.PKT);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.RATE
            : orderValue + Math.abs(diff) * row.RATE;
      } else {
        //formatToproperdecimals
        value = formatToProperDecimal(value);
        diff = value - Number(row.QTY);
        orderVal =
          diff < 0
            ? orderValue - Math.abs(diff) * row.CRATE_OR_NOT * row.RATE
            : orderValue + Math.abs(diff) * row.CRATE_OR_NOT * row.RATE;
      }
      if (
        diff > 0 &&
        creditLimit[0].FD_LOCK === "Y" &&
        orderVal > Number(creditLimit[0].CREDIT_LIMIT)
      ) {
        toast.error(`Adding this item will exceed the credit limit`, {
          toastId: "credit-limit-error",
        });
        return;
      }

      setOrderValue(orderVal);
      row.INDENT_IN === "C" ? (row.QTY = value) : (row.PKT = value);
    }
    objCopy.splice(index, 1, row);
    setCategoryItems(objCopy);
  };

  const mymulUpdate = (index, value, type = undefined) => {
    if (!value || value < 0) return;

    let objCopy = [...categoryItems];
    let row = objCopy[index];
    let diff = row.PKT;
    let orderVal;

    if (value > row.FGS_QTY) {
      toast.error(`${row.FGS_QTY} units in stock`, {
        toastId: "out-of-stock-error",
      });
      return;
    }

    if (type === "pkt") {
      diff = value - diff;

      if (value < row.MINORDER) {
        toast.error(`Minimum order qty is ${row.MINORDER}`, {
          toastId: "min-order-error",
        });
        return;
      }

      if (value > row.MAXORDER) {
        toast.error(`Maximum order qty is ${row.MAXORDER}`, {
          toastId: "max-order-error",
        });
        return;
      }

      if (value % row.MINORDER !== 0) {
        toast.error(`Qty must be in multiple of ${row.MINORDER}`, {
          toastId: "not-multiple-error",
        });
        return;
      }

      row.PKT = value;
      row.QTY = value / row.CRATE_OR_NOT;
    }

    if (type === "crate") {
      let convertedVal = value * row.CRATE_OR_NOT;
      diff = convertedVal - diff;

      if (convertedVal < row.MINORDER) {
        toast.error(`Minimum order qty is ${row.MINORDER}`, {
          toastId: "min-order-error",
        });
        return;
      }

      if (convertedVal > row.MAXORDER) {
        toast.error(`Maximum order qty is ${row.MAXORDER}`, {
          toastId: "max-order-error",
        });
        return;
      }

      if (convertedVal % row.MINORDER !== 0) {
        toast.error(`Qty must be in multiple of ${row.MINORDER}`, {
          toastId: "not-multiple-error",
        });
        return;
      }
      row.PKT = convertedVal;
      row.QTY = value;
    }

    orderVal =
      diff < 0
        ? orderValue - Math.abs(diff) * row.RATE
        : orderValue + Math.abs(diff) * row.RATE;

    if (
      diff > 0 &&
      creditLimit[0].FD_LOCK === "Y" &&
      orderVal > Number(creditLimit[0].CREDIT_LIMIT)
    ) {
      toast.error(`Adding this item will exceed the credit limit`, {
        toastId: "credit-limit-error",
      });
      return;
    }

    objCopy.splice(index, 1, row);

    setOrderValue(orderVal);
    setCategoryItems(objCopy);
    setValuesChanged(true);
  };

  const handleNotifyMe = async (packetId) => {
    try {
      let customerId = localStorage.getItem("userId");
      await getRequest(urls.notify(customerId, packetId));
      toast.success("You will be notified when it is available");
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const saveIndent = async () => {
    try {
      setLoading(true);
      let qtyArr = [];
      let idArr = [];
      let indentIn = [];
      categoryItems.map((item) => {
        let qty = item.INDENT_IN === "C" ? String(item.QTY) : String(item.PKT);
        qtyArr.push(qty);
        idArr.push(item.PACKET_CODE);
        indentIn.push(item.INDENT_IN);
      });

      await getRequest(
        urls.saveIndent(location.state.indentNumber, qtyArr, idArr, indentIn)
      );
      setValuesChanged(false);
      toast.success("Indent saved successfully.");
      navigate(-1, { replace: true });
    } catch (error) {
      toast.error("Could not save indent, try again.");
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (ValuesChanged) setIsModalOpen(true);
    else navigate(-1, { replace: true });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const searchProducts = async () => {
    try {
      setLoading(true);
      const response = await getRequest(
        urls.searchProducts(location.state.indentNumber, location.state.keyword)
      );
      response[0].forEach((element) => {
        element["QTY"] = 0;
      });
      setCategoryItems(response[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong, try again");
    }
  };

  useEffect(() => {
    if (location.state.keyword) searchProducts();
    else getCategoryItems();
  }, []);

  return (
    <div className="category-items">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          flexDirection: "column",
        }}
      >
        <div
          className="order-value text-ellipsis"
          style={{ fontWeight: 500, color: "#7B7B7B" }}
        >
          Order Value:{" "}
          <span style={{ color: "#0052A0" }}>
            {" "}
            {inr} {orderValue?.toFixed(2)}
          </span>
        </div>
        <div
          className="category-name text-ellipsis"
          style={{ fontWeight: 600 }}
        >
          {location.state.categoryName || location.state.keyword}
        </div>
      </div>

      <div className="indent-list category-items-scroll-area">
        {loading ? <ListLoader /> : null}
        {categoryItems.length ? (
          categoryItems.map((item, index) => (
            <ItemCard
              item={item}
              index={index}
              updateValues={updateValues}
              mymulUpdate={mymulUpdate}
              handleNotifyMe={handleNotifyMe}
              indentNumber={{ RATE_CATAG: location.state.rateCatag }}
              appFlavour={appFlavour}
            />
          ))
        ) : (
          <div style={{ marginTop: 20 }}>
            <Empty description="No items found" />
          </div>
        )}
      </div>
      <div className="continue-btn">
        <button
          className={ValuesChanged ? "secondary-btn" : "primary-btn"}
          onClick={() => handleBack()}
        >
          Back
        </button>
        {ValuesChanged ? (
          <button
            className="primary-btn"
            onClick={() => {
              if (
                creditLimit[0].FD_LOCK === "Y" &&
                orderValue > Number(creditLimit[0].CREDIT_LIMIT)
              ) {
                toast.error(`Credit limit exceeded`, {
                  toastId: "credit-limit-error",
                });
                return;
              }
              saveIndent();
            }}
          >
            Save
          </button>
        ) : null}
      </div>

      <Modal
        className="bottom-modal"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="header-img-container">
          <ExclamationCircleOutlined
            style={{ fontSize: 60, color: "#d72b2b" }}
          />
        </div>

        <p>You have not saved the changes, do you want to leave this page?</p>
        <div className="action-btns">
          <button className="secondary-btn" onClick={() => handleCancel()}>
            No
          </button>
          <button
            className="primary-btn"
            onClick={() => navigate(-1, { replace: true })}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  );
}
