import React from "react";
import { DatePicker, Empty, Form, Spin, Table, Popover, Button } from "antd";
import { useState } from "react";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import { Typography } from 'antd';
import { Select } from "antd";
import { getRequest,putRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import swal from 'sweetalert2';

export default function Anurag() {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [displayStatus,SetDisplayStatus]=useState(null);
  const [loading, setLoading] = useState(false);
  const customerId = localStorage.getItem("userId");
  const [dateForm] = Form.useForm();
  const { Title } = Typography;


  const getPaymentStatus = async () => {
    const customer_code = customerId;
    const paymentStatus = selectedStatus;
    const res = await getRequest(
      urls.getpaymentStatusDetails(customer_code, paymentStatus)
    );
    setTransactions(res);
    SetDisplayStatus(selectedStatus);
  };

  const handleOrderClick = async (record) => {
    const res = await getRequest(
      urls.getpaymentStatusRazorPay(record.ORDER_ID)
    );

   
    if (res.count !== 0) {
      if(res.items[res.items.length - 1].captured===true){
        if(record.STATUS!=='Success'){
          const updateStatus = await putRequest(
            urls.putRpay_Details_Status(customerId, record.ORDER_ID,record.AMOUNT,"Success",res.items[0].id)
          );
          getPaymentStatus();
          swal.fire('Success','Your payment has been Successfull','success');
        }else if(record.STATUS==='Success'){
          swal.fire('Success','Your payment has already been Successfull','success');
        }
      }else if(res.items[res.items.length - 1].captured!==true){
        swal.fire('Failed',`Your payment has been ${record.STATUS}`,'error');
      }
    } else if (res.count === 0) {
      swal.fire({
        icon: 'warning',
        title: 'Fail',
        html: `You have not completed payment cycle`
      });
    }
  };

  



  const onlineTransactionsColumns = [
    { title: "Date", dataIndex: "INITIATED_DT", width: 35 },
    {
      title: "Amount",
      dataIndex: "AMOUNT",
      width: 50,
      align: "center",
    },
    {
      title: "Order Id",
      dataIndex: "ORDER_ID",
      width: 110,
      align: "center",
      render: (text, record) => (
        <a href="#" onClick={() => handleOrderClick(record)}>
          {text}
        </a>
      ),
    },
  ];

  return (
    <div>
      <Form
        className="date-range-form"
        form={dateForm}
        layout={"horizontal"}
        onFinish={getPaymentStatus}
        requiredMark={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        <Form.Item
          noStyle
          name={"batch"}
          rules={[{ required: true, message: "Please select to Batch" }]}
        >
          <Select
            name="select-batches"
            placeholder="Payment Status"
            style={{ width: "169px" }}
            onChange={(val) => {
              setSelectedStatus(val);
            }}
            value={selectedStatus}
          >
            <Select.Option value="Success">Success</Select.Option>
            <Select.Option value="Failed">Failed</Select.Option>
            <Select.Option value="Cancelled">Cancelled</Select.Option>
            <Select.Option value="Initiated">Initiated</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item noStyle>
          <button
            className="primary-btn"
            style={{
              width: 60,
              padding: 5,
              boxShadow: "none",
              borderRadius: "none",
            }}
            onClick={() => dateForm.submit()}
          >
            Go
          </button>
        </Form.Item>
      </Form>

      {!loading && transactions ? (
        <>
        <Title level={5} style={{textAlign:'center'}}>Payment {displayStatus} List</Title>
        <Table
          dataSource={loading ? [] : transactions}
          locale={{
            emptyText: loading ? <Spin /> : <Empty />,
          }}
          columns={onlineTransactionsColumns}
          scroll={{ y: "calc(100vh - 350px)", x: 100 }}
          pagination={false}
          style={{border:"1px solid black",borderRight:"2px solid black",}}
        />
        </>
      ) : null}

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
          
        </div>
      ) : null}
    </div>
  );
}
