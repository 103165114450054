import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../../http/axiosConfig/config";
import urls from "../../../http/endpoints/urls";
import Card from "../../re-usables/card/Card";
import ListLoader from "../../re-usables/loaders/ListLoader";
import "./Order.scss";
import OrderItem from "./OrderItem";
import Swal from "sweetalert2";
import moment from "moment";

// Extend dayjs with the isBetween plugin

export default function Order() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [cart, setCart] = useState([]);
  const [priceBreakdown, setPriceBreakdown] = useState([]);
  const [netAmount, setNetAmount] = useState("");
  const [finalOrderVal, setFinalOrderVal] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const appFlavour = process.env.REACT_APP_FLAVOUR;
  const [paymentTimeTumul, setPaymentTimeTumul] = useState([]);

  const getCartDetails = async () => {
    try {
      setLoading(true);
      let indentNumber = location.state.indentNumber;
      const response = await getRequest(urls.getCart(indentNumber));

      if (appFlavour !== "MYMUL") {
        const priceBreakdown = await getRequest(
          urls.getPriceBreakdown(indentNumber)
        );
        setPriceBreakdown(priceBreakdown[0]);
      }

      if (appFlavour !== "MYMUL") {
        const netAmount = await getRequest(urls.getNetAmount(indentNumber));
        setNetAmount(netAmount[0]);
      }
      const orderVal = await getRequest(urls.finalOrderValue(indentNumber));
      setCart(response[0]);
      setFinalOrderVal(orderVal[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getPaymentTime = async () => {
    try {
      const PaymentTime = await getRequest(urls.paymentTime());
      setPaymentTimeTumul(PaymentTime[0]);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    getCartDetails();
    getPaymentTime();
  }, []);

  if (loading)
    return (
      <div style={{ height: "calc(100vh - 281px)", overflowY: "hidden" }}>
        <ListLoader />
      </div>
    );

  return (
    <div className="cart-items">
      <div style={{ fontWeight: 600 }}>Order Details</div>
      <div className="cart-items-scroll-area">
        {cart.length
          ? cart.map((item) => (
            <OrderItem item={item} rateCatag={location.state.rateCatag} />
          ))
          : null}
        <Divider />

        <div className={appFlavour === "MYMUL" ? "net-amount" : ""}>
          <div className="flex-row">
            <div className="key">Order Value</div>
            <div className="value" style={{ fontWeight: 600 }}>
              {inr} {Number(finalOrderVal.TOTAL).toFixed(2)}
            </div>
          </div>
        </div>

        <div className="price-breakdown">
          {priceBreakdown.length
            ? priceBreakdown.map((item) => (
              <div className="flex-row">
                <div className="key">{item.ADD_DED_NAME}</div>
                <div className="value">
                  {inr} {Number(item.AMT).toFixed(2)}
                </div>
              </div>
            ))
            : null}

          {appFlavour !== "MYMUL" ? (
            <>
              <Divider />
              <div className="net-amount">
                <div className="flex-row">
                  <div className="key">Net Payable</div>
                  <div className="value">
                    {inr} {Number(netAmount.TOTAL).toFixed(2)}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="continue-btn">
        <button
          className="secondary-btn"
          onClick={() => navigate(-1, { replace: true })}
        >
          Back
        </button>
        {creditLimit[0].PAY_MODE === "B" && appFlavour !== "MYMUL" ? (
          <button
            className="primary-btn"
            onClick={() => {
              // ==================================================================
              //THIS IS USING DAYJS WORKS FINE HARD CODED FOR TUMUL
              // if (currentTime.isBetween(dayjs().startOf('day').set('hour', 9), dayjs().startOf('day').set('hour', 13))) {
              //   sessionStorage.setItem("initialPayment", true);
              //   return navigate("/payment", {
              //     state: { amount: netAmount.TOTAL },
              //     replace: true,
              //   });
              // }
              // else {
              //   Swal.fire('Warning', "You can only make payment between 9:00 AM to 1:00 PM", 'warning');
              // }
              // ==================================================================
              let isBetween = moment().isBetween(
                moment(paymentTimeTumul.ST_TIME, "hh.mm A"),
                moment(paymentTimeTumul.END_TIME, "hh.mm A"),
                "minutes"
              );
              if (!isBetween) {
                sessionStorage.setItem("initialPayment", true);
                return navigate("/payment", {
                  state: { amount: netAmount.TOTAL },
                  replace: true,
                });
              }
              else {
                Swal.fire('Warning', `${paymentTimeTumul.MSG}`, 'warning');
              }
            }}
          >
            Pay
          </button>
        ) : (
          <button
            className="primary-btn"
            onClick={() => {
              return navigate("/", { replace: true });
            }}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
}
