import { Form, InputNumber, Spin, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import urls from "../../../http/endpoints/urls";
import moment from "moment";
import Swal from "sweetalert2";
import { BASE_URL, getRequest } from "../../../http/axiosConfig/config";


export default function ManualPayment() {
  const inr = <span style={{ fontFamily: "sans-serif" }}>&#x20B9;</span>;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [challanDetails, setChallanDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const appFlavour = process.env.REACT_APP_FLAVOUR;
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));
  const [paymentTimeTumul, setPaymentTimeTumul] = useState([]);
  const [customerRoutes, setCustomerRoutes] = useState([])
  const [selectedBatch, setSelectedBatch] = useState("")
  const [hasError, setHasError] = useState(false);


  const handleSubmit = async (values) => {
    let isBetween = moment().isBetween(
      moment(paymentTimeTumul.ST_TIME, "hh.mm A"),
      moment(paymentTimeTumul.END_TIME, "hh.mm A"),
      "minutes"
    );
    if (!isBetween) {
      sessionStorage.setItem("initialPayment", true);
      let amount = values.amount;
      form.resetFields();
      // return navigate("/payment", {
      //   state: { amount: amount },
      //   replace: true,
      // });
      // ================================================================

      handlePayment(localStorage.getItem("userId"), amount, 0, selectedBatch);
    }
    else {
      Swal.fire('Warning', `${paymentTimeTumul.MSG}`, 'warning');
    }


  };

  // const handleSubmit = async (values) => {
  //   console.log();
  //   window.location.href = 'https://indtmu.in/razorpay_test2.html';
  // };

  const handlePayment = async (userId, formattedAmount, INDENT_NUMBER, ROUTE_CODE) => {
    console.log(INDENT_NUMBER, ROUTE_CODE, userId, formattedAmount);

    try {
      setHasError(false);
      // const order = await getRequest(
      //   `${BASE_URL}/create_order.php?customer_code=${userId}&amount=${formattedAmount}`
      // );
      const order = await getRequest(
        `${BASE_URL}/cr_order3.php?amount=${formattedAmount}&customer_code=${userId}`
      );

      if (!order || !order.order_id)
        throw new Error("Order Id not returned by server");
      const orderId = order.order_id;
      try {
        const phpscript = await getRequest(
          `${BASE_URL}/insert_order.php?customer_code=${userId}&amount=${formattedAmount}&order_id=${orderId}&route_code=${ROUTE_CODE}&gp_date=${moment().format("DD-MMM-YY")}&indent_number=${INDENT_NUMBER}&pay_type=${"o"}`
        );
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }

      const payement_deatils = {
        orderid: orderId,
        amount: formattedAmount,
        customer_code: userId,
        ispayment: true,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(payement_deatils));
    } catch (error) {
      console.log(error);
      setHasError(true);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  };

  const getChallanDetails = async () => {
    try {
      setLoading(true);
      let customerId = localStorage.getItem("userId");
      const challanDetails = await getRequest(
        urls.getChallanDetails(customerId)
      );
      setChallanDetails(challanDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const getPaymentTime = async () => {
    try {
      const PaymentTime = await getRequest(urls.paymentTime());
      setPaymentTimeTumul(PaymentTime[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const getcustomerRoutes = async (customerCode) => {
    try {
      const routes = await getRequest(urls.getCustomerRoutes(customerCode));
      setCustomerRoutes(routes);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (appFlavour === "MYMUL") {
      getChallanDetails();
    }
    getPaymentTime();
    getcustomerRoutes(localStorage.getItem("userId"));
  }, []);

  return creditLimit[0].PAY_MODE === "C" ? (
    <div style={{ marginTop: 20, fontWeight: 600 }}>
      You are not authorized to make online transactions.
    </div>
  ) : (
    <>
      <div className="page-header">Make Payment</div>
      {appFlavour !== "MYMUL" ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            marginTop: "100px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <>
            <Form
              className="manual-payment-form"
              requiredMark={false}
              form={form}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="amount"
                label={<h3>Enter amount</h3>}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please enter an amount",
                  },
                  {
                    validator: (_, value) => {
                      if (value >= 1) return Promise.resolve();
                      else
                        return Promise.reject("Amount must be greater than 0");
                    },
                  },
                ]}
              >
                <InputNumber
                  addonBefore={
                    <string style={{ fontFamily: "sans-serif" }}>
                      {" "}
                      &#x20B9;
                    </string>
                  }
                  style={{ width: "100%" }}
                  precision={2}
                  controls={false}
                />
              </Form.Item>

              <Form.Item
                name={"batch"}
                // label={<h3>Select Batch</h3>}
                style={{ width: "100%" }}
              >
                <Select
                  name="select-batches"
                  placeholder="Select Route"
                  onChange={(val) => {
                    setSelectedBatch(val);
                    if (sessionStorage.selectedBatch === undefined) {
                      sessionStorage.setItem("selectedBatch", JSON.stringify({ ROUTE_CODE: val }))
                    }
                    else {
                      const batch = JSON.parse(sessionStorage.selectedBatch)
                      sessionStorage.setItem("selectedBatch", JSON.stringify({ ...batch, ROUTE_CODE: val }))
                    }
                  }}
                  value={selectedBatch}
                >
                  {customerRoutes.map((item) => (
                    <Select.Option value={item.ROUTE_CODE} key={item.ROUTE_CODE}>
                      {item.ROUTE_NAME}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            {selectedBatch && (
              <button className="primary-btn" onClick={() => form.submit()}>
                Pay
              </button>
            )}
            {/* <button className="primary-btn" onClick={handleSubmit}>
              Pay
            </button> */}


          </>
        </div>
      ) : (
        <>
          {challanDetails.length ? (
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
                margin: "10px 0px",
                borderBottom: "1px solid #8080804d",
              }}
            >
              Bill No. {challanDetails[0].BILL_NO}
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Spin />
            </div>
          )}
          <div className="price-breakdown">
            {challanDetails.map((item) => (
              <div className="flex-row">
                <div className="key">{item.ADD_DED_NAME}</div>
                <div className="value">
                  {inr} {item.AMOUNT}
                </div>
              </div>
            ))}

            {!!challanDetails.length && (
              <>
                <div
                  className="flex-row"
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    margin: "10px 0px",
                    borderTop: "1px solid #8080804d",
                  }}
                >
                  <div className="key">Net Payable</div>
                  <div className="value">
                    {inr} {challanDetails[0].BILL_AMOUNT}
                  </div>
                </div>

                <div
                  className="pay-btn-section"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 35,
                  }}
                >
                  <button
                    className="primary-btn"
                    onClick={() =>
                      handleSubmit({ amount: challanDetails[0].BILL_AMOUNT })
                    }
                  >
                    Payy
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
