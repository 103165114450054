import React, { useEffect } from 'react';
import { Empty, Spin, Table, } from "antd";
import { useState } from "react";
import urls from "../../../http/endpoints/urls";
import { BASE_URL, getRequest } from "../../../http/axiosConfig/config";
import moment from "moment";
import Swal from "sweetalert2";


export default function OldPayments() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentTimeTumul, setPaymentTimeTumul] = useState([]);
  const creditLimit = JSON.parse(sessionStorage.getItem("creditLimit"));

  const columns = [
    {
      title: "Date", dataIndex: "GP_DATE", width: 40,
    },
    { title: "Route name", dataIndex: "ROUTE_NAME", width: 30 },
    {
      title: "Amount",
      dataIndex: "TOTAL",
      width: 35,
      render: (text) => `Rs ${text}`  // Render function to prepend "Rs" to the amount
    },
    { title: "Route Code", dataIndex: "ROUTE_CODE", width: 35, hidden: true },
    { title: "Indent Number", dataIndex: "INDENT_NUMBER", width: 35, hidden: true },
    {
      title: "Action",
      dataIndex: "Action",
      width: 25,
      render: (text, record) => (
        <button
          style={{
            backgroundColor: "#23b0fc",
            color: "white",
            border: "none",
            padding: "5px 8px",
            width:"80%",
            borderRadius: "4px",
            margin: "5px 5px",
          }}
          onClick={() => { handlepayButtonClick(record) }}
        >
          Pay
        </button>
      ),
    }
  ].filter(item => !item.hidden);

  const handlepayButtonClick = (record) => {
    // console.log(record)
    // let isBetween = moment().isBetween(
    //   moment(paymentTimeTumul.ST_TIME, "hh.mm A"),
    //   moment(paymentTimeTumul.END_TIME, "hh.mm A"),
    //   "minutes"
    // );
    // if (isBetween) {
    handlePayment(localStorage.getItem("userId"), record.TOTAL, record.ROUTE_CODE, record.INDENT_NUMBER, record.GP_DATE);
    // }
    // else {
    // Swal.fire('Warning', `${paymentTimeTumul.MSG}`, 'warning');
    // }
  }
  const handlePayment = async (userId, formattedAmount, ROUTE_CODE, INDENT_NUMBER, GP_DATE) => {
    console.log("userId", userId);
    console.log("formattedAmount", formattedAmount);
    console.log("ROUTE_CODE", ROUTE_CODE);
    try {

      // const order = await getRequest(
      //   `${BASE_URL}/create_order.php?customer_code=${userId}&amount=${formattedAmount}`
      // );
      const order = await getRequest(
        `${BASE_URL}/cr_order3.php?amount=${formattedAmount}&customer_code=${userId}`
      );

      if (!order || !order.order_id)
        throw new Error("Order Id not returned by server");
      const orderId = order.order_id;
      try {
        const phpscript = await getRequest(
          `${BASE_URL}/insert_order.php?customer_code=${userId}&amount=${formattedAmount}&order_id=${orderId}&route_code=${ROUTE_CODE}&gp_date=${GP_DATE}&indent_number=${INDENT_NUMBER}&pay_type=${"R"}`
        );
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
      const payement_deatils = {
        orderid: orderId,
        amount: formattedAmount,
        customer_code: userId,
        ispayment: true,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(payement_deatils));
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  };

  const getPaymentTime = async () => {
    try {
      const PaymentTime = await getRequest(urls.paymentTime());
      setPaymentTimeTumul(PaymentTime[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const getOldPaymentList = async () => {
    setLoading(true)
    try {
      const res = await getRequest(
        urls.getOldBalance(localStorage.getItem("userId"))
      );
      setRows(res);
      setLoading(false)
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }
  useEffect(() => {
    getOldPaymentList()
    getPaymentTime()
  }, [])
  return (
    <>
      <div>
        <div className="page-header">Payments not done list</div>
      </div>
      <Table
        dataSource={rows}
        locale={{
          emptyText: loading ? <Spin /> : <Empty />,
        }}
        columns={columns}
        scroll={{ y: "calc(100vh - 350px)", x: 100 }}
        pagination={false}
        style={{ border: "1px solid black", borderRight: "2px solid black", }}
      />
    </>
  )
}




